



























































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { vxm } from "@/store";
import RegisterPort from "@/views/RegisterPort.vue";


@Component({
  components: {
    RegisterPort,
  },
})
export default class Home extends Vue {
  private theUi = vxm.ui;

  created() {
    this.theUi.closeAside();
  }
}
